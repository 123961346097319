body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

h1 {
  color: #333;
}

#root {
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 40%;
}

input[type="text"],
input[type="number"],
input[type="date"] {
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 20%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

#addTodo Button {
  width: 30%;
  float: left;
}

button:hover {
  background-color: #0056b3;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #f9f9f9;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li pre {
  margin: 0;
  white-space: pre-wrap;
}

li button {
  background-color: #28a745;
  margin-left: 10px;
}

li button:hover {
  background-color: #218838;
}

li button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}